import axios from 'axios'

// const _baseURL = 'http://127.0.0.1:8006'
const _baseURL = `http://${process.env.REACT_APP_DOMAIN}`

const NOTIFICATION_URL = 'ws://127.0.0.1:8006'

//FOR AXIOS INSTANCE MAPING URL
const appInstance = axios.create({ baseURL: `${_baseURL}/api` })
export const staticPath = _baseURL
export default appInstance


//FOR NOTIFICATIOIN WEB SOCKET MAPING URL
export const wsURL = `${NOTIFICATION_URL}/ws`